$(document).ready(function(){
    $('.parallax-window').parallax({
      imageSrc: 'img/bg-5.jpg',
      bleed: 25,
      speed: 0.2
    });
});

$(document).ready(function(){
  var mobileNavOpen = false;
  $("#hamburger").click(function() {
      if ( $("#hamburger").hasClass("hamburger-active" )) {
        mobileNavOpen = true;
        // console.log("this click caused the nav to close")
      }
      else {
        mobileNavOpen = false;
        // console.log("this click caused the nav to open")
      }
      $(this).toggleClass("hamburger-active");
      $("#main-nav").toggleClass("mobilenav-active")

    });

  $("#main-nav a").click(function() {
    $("#hamburger").toggleClass("hamburger-active");
    $("#main-nav").toggleClass("mobilenav-active")
  });
});
